$(document).ready(function () {

  var elementKindSelector = $("[name='element[kind]']");
  elementKindSelector.on('change', function(event) {
    updateElements(($(this).val()));
  });

  updateElements(elementKindSelector.val());

  function updateElements(selectedValue){

    var inputs = $('#elements').find('[data-tile]');
    inputs.each(function(){
      var optionArray = ($(this).data('tile'));
      if (optionArray){
        if (optionArray.includes(selectedValue)){
          $(this).prop( "disabled", false );
          $(this).removeClass("disabled");
        } else {
          $(this).prop( "disabled", true );
          $(this).addClass("disabled");
        }
      }
    })
  }
});
