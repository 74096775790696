$(document).on('click', '[data-toggle="modal"][data-target="#loginModal"]', function (event) {
  $('#redirect_url').val('');

  if ($(this).data('redirect-url')) {
    $('#redirect_url').val($(this).data('redirect-url'));
  }
});

$(document).on('click', '#forgot-password-link, #signin-link', function(event){
  $('.forgot_password').toggleClass('hidden');
  $('.signin').toggleClass('hidden');
});
