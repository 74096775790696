(function(){
  $(document).ready(function () {
    $('.js-file-upload').each(function(event) {
      var element = $(this)[0];
      var inputElement = element.querySelector('input');
      var displayLabel = element.querySelector('.input-label');

      var placeholderText = inputElement.getAttribute('placeholder');
      displayLabel.innerText = placeholderText? placeholderText : 'Selecteer bestand';

      $(inputElement).on('change', function(event) {
        const value = $(this).val();
        const length = $(this).val().split("\\").length;
        displayLabel.innerText = length > 0 ? value.split("\\")[length - 1] : value;
        if (element.classList.contains('js-no-preview')) { return; }
        var inputElement = element.querySelector('input');
        if (inputElement && inputElement.classList.contains('js-no-preview')) { return; }
        showPreview(this);
      });
    });
  });

  function showPreview(input) {
    if (input.files && input.files[0]) {
      let selector = input.dataset.onChangeCopyTarget;
      if (!selector) { selector = '.js-file-upload-preview'}

      const preview = $(selector);
      const uploaded_preview = preview.siblings('.preview-image-uploaded');
      if (uploaded_preview) {
        uploaded_preview.css('display', 'none');
      }

      const allowedArray  = input.dataset.onlyImages !== undefined ? ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'] : null;

      const fileType = input.files[0].type;
      const isAllowed = allowedArray === null || allowedArray.indexOf(fileType) > -1;
      if (!isAllowed){
        preview.css('border', '2px solid red');
        input.value = '';
        return
      }

      if(['image/png', 'image/jpg', 'image/jpeg', 'image/gif'].indexOf(fileType) > -1) {
        const reader = new FileReader();
        reader.onload = function (e) {
          preview.html('');
          preview.css('background-image', 'url(' + e.target.result + ')');
          preview.hide();
          preview.fadeIn(650);
        };
        reader.readAsDataURL(input.files[0]);
      } else {
        preview.html("<span style='padding: 0.5rem; display: inline-block;'>Geen preview mogelijk</span>");
        preview.css('background-image', 'none');
        preview.show();
      }
    }
  }
})();
