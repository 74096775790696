const Storage = (function(){

  let tmpStorage = {}

  function getItem(key) {
    try {
      if (localStorage) {
        return localStorage.getItem(key)
      } else {
        return '-1'
      }
    } catch (ex) {
      return '-1'
    }
  }

  function getFromTmp(key) {
    return tmpStorage[key] == undefined ? null : tmpStorage[key]
  }

  function setItem(key, flag) {
    storeItem(key, flag);
  }

  function storeObject(key, data) {
    storeItem(key, JSON.stringify(data));
  }

  function storeItem(key, data) {
    try {
      if (localStorage) {
        localStorage.setItem(key, data);
      } else {
        tmpStorage[key] = data;
      }
    } catch(ex) {
      ErrorHandler.captureException(ex);
      tmpStorage[key] = data;
    }
  }

  return {
    getItem: getItem,
    setItem: setItem,
    storeObject: storeObject
  }
})();

export default Storage;
