(function() {
  var stickyElement;
  var stickyContainerElement;
  var diff;

  $(window).on('load', function () {
    stickyElement = $("#sticky");
    stickyContainerElement = $("#sticky-container");

    if (!stickyElement.length) { return; }
    if (!stickyContainerElement.length) {return; }

    stickyElement.css('will-change', 'transform');

    window.onresize = function () {
      calculatePosition();
    };

    window.onscroll = function () {
      toggleSticky()
    };

    calculatePosition();
  });

  function calculatePosition() {
    diff = 0;
    var offsetRight = stickyElement.offset().left - stickyElement.width() - 28;
    var width = stickyElement.width() + 28;

    // stickyElement.css("right", offsetRight + 'px');
    stickyElement.css("width", width + 'px');
  }

  function toggleSticky() {
    requestAnimationFrame(updateElements);
  }

  function updateElements(){

    var eTop = stickyContainerElement.offset().top;
    var eBottom = stickyContainerElement.offset().top + stickyContainerElement.height();

    var overflow =  $(window).scrollTop() + stickyElement.height() > (eBottom - 80);
    if (overflow){
      stickyElement.css('transform','translateY(' + (diff * -1) + 'px)');
      stickyElement.removeClass("sticky");
      return;
    }

    stickyElement.css('transform','translateY(0px)');

    diff = eTop - $(window).scrollTop();
    if (diff < 0) {
      stickyElement.addClass("sticky");
    } else {
      stickyElement.removeClass("sticky");
    }
  }
})();
