import Rails from "rails-ujs";
import Storage from "./storage";

const Wizard = (function () {
  function init() {

    const wizardElements = Array.from(document.querySelectorAll('[data-wizard]'))
    wizardElements.forEach(wizardElement => {
      const wizardShown = Storage.getItem(wizardElement.id)
      if (wizardShown != '1' && wizardShown != '-1') {
        initWizard(wizardElement)
      }
    })
  }

  function initWizard(wizardElement) {
    wizardElement.closest('.wizard-wrapper').classList.add('is--active');
    const sliderElements = Array.from(wizardElement.querySelectorAll('[data-step]'))
    sliderElements.forEach((sliderElement) => {
      const clickElement = sliderElement.querySelector('[data-to-step]')
      const dismissElement = sliderElement.querySelector('[data-dismiss="wizard"]')

      if (clickElement) {
        clickElement.addEventListener('click', handleSliderElementClick);
      }

      if (dismissElement){
        dismissElement.addEventListener("click", handleSliderDismiss);
      }
    });

    Storage.setItem(wizardElement.id, '1')
  }

  function handleSliderElementClick(event) {
    const element = event.currentTarget;
    const wizard = element.closest('[data-wizard]')
    const sliderElement = wizard.querySelector('[data-step="' + element.dataset.toStep + '"]')
    const t = getContainerHalfPoint(sliderElement)
    const i = getElementHalfPoint(sliderElement)
    const left = i - t;

    setScrollPosition(left, sliderElement);
  }

  function getContainerHalfPoint(sliderElement) {
    const sliderContainer = sliderElement.closest('[data-wizard]')
    const n = sliderContainer.offsetWidth
    const t = sliderContainer.clientLeft;
    return t + n / 2;
  }

  function getElementHalfPoint(element) {
    const t = _getOffset(element);
    const i = element.offsetWidth;
    return t.left + i / 2
  }


  function _getOffset(n) {
    return {
      top: n.offsetTop,
      left: n.offsetLeft
    }

    for (let t = 0, i = 0; n && !isNaN(n.offsetLeft) && !isNaN(n.offsetTop);)
      t += n.offsetLeft - n.scrollLeft,
        i += n.offsetTop - n.scrollTop,
        n = n.offsetParent;
    return {
      top: i,
      left: t
    }
  }

  function setScrollPosition(left, sliderElement) {
    const sliderContainer = sliderElement.closest('[data-wizard]')
    sliderContainer.scroll != undefined ? sliderContainer.scroll({
      top: 0,
      left: left,
    }) : sliderContainer.scrollLeft = left;
  }

  function handleSliderDismiss(event) {
    const element = event.currentTarget;
    const wizard = element.closest('[data-wizard]')
    const wizardWrapper = wizard.closest('.wizard-wrapper');

    wizard.remove();
    if (wizardWrapper){
      wizardWrapper.remove();
    }
  }
  return {
    init: init,
  }
})();

document.addEventListener("DOMContentLoaded", function (_event) {
  Wizard.init();
});
