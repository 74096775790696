(function(){
  $(document).ready(function () {
    Counter.init();
    observe_data_on_change_copy_target_fields();

    $('[data-on-change-copy-target]').each(function () {
      handleDataChange($(this));
    });

    $('.js-load-initial').each(function () {
      var value = $(this).html()
      var corrected = value.replaceAll('\n', '<br/>');
      $(this).html("" + corrected);
    });
  });

  function observe_data_on_change_copy_target_fields() {
    $('[data-on-change-copy-target]').on('keyup change', function () {
      handleDataChange($(this))
    });
  }

  function handleDataChange($element) {
    var target = $($element.data('on-change-copy-target'));
    var value = $element.val();

    if (isDate($element, value)) {
      value = format_date(value)
    } else if (isTime($element)) {
      value = format_time(value)
    }

    if (target.hasClass('js-has-file-upload-preview')) {
      return;
    }

    if (target.hasClass('js-video_url_preview')) {
      target.attr('data-embed-video-url', value).data('embed-video-url', value).empty();
      $(window).trigger('generate-video-embed');
      return;
    }

    if (target.prop('tagName') === "INPUT") {
      if (target.prop("type") === 'checkbox') {
        target.prop('checked', $element.prop('checked'));
      } else {
        target.val(value);
      }
    } else {
      if (value == null || value === "") {
        target.html("&nbsp;")
      } else {
        var corrected = value.replaceAll('\n', '<br/>');
        target.html("" + corrected);
      }
    }

    // if (target.hasClass('js-count')) {
    //   var length = target.text().length;
    //   var resultElement = $($element.data('on-change-copy-target') + '-count');
    //   resultElement.text(length + "");
    //   if (length > 280) {
    //     resultElement.css("color", 'red');
    //   } else if (length > 200) {
    //     resultElement.css("color", 'green');
    //   } else {
    //     resultElement.css("color", 'green');
    //   }
    // }
  }

  function isDate($element, value) {
    if ($element.data('type') === 'date') {
      return true;
    }

    var dateMiliseconds = Date.parse(value);
    if (isNaN(dateMiliseconds) == false && dateMiliseconds > 0) {
      var date = new Date(dateMiliseconds);
      if (date > new Date(2019,1,1)) {
        return true
      }
    }
  }

  function isTime($element,) {
    return $element.data('type') === 'time'
  }

  function format_date(value) {
    var split = value.split('-');
    var reversed_date_value = [split[2], split[1], split[0]].join('-');
    return reversed_date_value;
  }

  function format_time(value) {
    var hours = value.split('.')[0].split(':')[0];
    var minutes = value.split('.')[0].split(':')[1];
    return hours + ':' + minutes
  }
})();
