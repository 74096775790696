$(document).ready(function () {

  $(".js-part").each(function () {
    var part = $(this);
    var hasErrors = part.find('.has-error').length > 0;

    var disabled = true;
    var inputs = part.find(':input:not([type=button]):not([type=submit])');
    var submits = part.find(':input[type=submit]');
    var cancelElement = part.find(':input[type=reset]');
    toggleInputs(part, !hasErrors);
    toggleButton(submits, hasErrors);

    cancelElement.on('click', function (e) {
      window.setTimeout(function () {
        toggleInputs(part, !hasErrors);
        toggleButton(submits, hasErrors);
        cancelElement.addClass("invisible");
      }, 100);
    });

    submits.on('click', function (e) {
      if (inputs.prop('disabled') == true) {

        $(".js-part").each(function () {
          toggleInputs($(this), true);
          toggleButton($(this).find(':input[type=submit]'));
          $(this).find(':input[type=reset]').addClass("invisible");
          $(this).find(':input[type=reset]').closest('form').trigger("reset");
        });

        cancelElement.removeClass("invisible");
        toggleInputs(part, false);
        toggleButton(submits, true);
        event.stopPropagation();
        event.preventDefault();
        return false;
      } else {

      }
    })
  });

  function toggleInputs($element, value) {
    $element.find(':input:not([type=button]):not([type=submit]):not([type=reset])').each(function () {
      toggleInput($(this), value);
    });
  }

  function toggleInput($input, value) {
    if ($input.is("input:file")) {
      if (value) {
        $input.parent().addClass('disabled');
      } else {
        $input.parent().removeClass('disabled');
      }
    } else if ($input.is("input:radio")) {
      if (value) {
        $input.parent().addClass('disabled');
      } else {
        $input.parent().removeClass('disabled');
      }
    }
    $input.prop('disabled', value);
  }

  function toggleButton($buttons, value) {
    if (value) {
      $buttons.val('Opslaan');
      $buttons.addClass('btn-primary');
      $buttons.removeClass('btn-default');
    } else {
      $buttons.val('Aanpassen');
      $buttons.removeClass('btn-primary');
      $buttons.addClass('btn-default');
    }
  }
});
