(function(){
  $(document).ready(function () {
    var sizeElement = $('#banner_size');
    if (sizeElement.attr('aria-invalid') == "true") {
      var errorSpan = sizeElement.next();
      $('.js-banner-header').addClass('invalid');
      $('.js-banner-header').append('<span class="help-block">' + errorSpan.html() + '<span>')
    }

    $('.js-banner').on('click', function(event) {
      var value = $(this).data('value');
      $('.js-banner').removeClass('banner-size--selected');
      $(this).addClass('banner-size--selected');
      sizeElement.val(value);
      $('.js-file-upload-preview').removeClass('banner-size--selected');
      $('.js-banner-preview-' + value).addClass('banner-size--selected');
    });
  });
})();
