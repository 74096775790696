(function(){

  var $trackingElement = null;
  var trackingData = {};

  $(document).ready(function () {
    observeTrackers();
    handleProductPicker();
    handleProductTypes();
  });

  function observeTrackers(){
    $trackingElement = $('#tracking_element');
    if ($trackingElement === undefined || $trackingElement === null) { return; }

    var $observers = $('[data-observe]');
    var $click_observers = $('[data-click-observe]');

    $observers.on('change', function(){
      var $changedElement = $(this);
      trackingData[$changedElement.data('trackingName')] = new Date().toLocaleString();

      setTrackingElementValue();
    });

    $click_observers.on('click', function(){
      var $changedElement = $(this);
      trackingData[$changedElement.data('trackingName')] = new Date().toLocaleString();

      setTrackingElementValue();
    });

    $observers.each(function(){
      var $changedElement = $(this);
      trackingData[$changedElement.data('trackingName')] = new Date().toLocaleString();

      setTrackingElementValue();
    });
  }

  function setTrackingElementValue() {
    $trackingElement.val(JSON.stringify(trackingData));
  }

  function handleProductPicker(){
   var element = document.querySelector('[data-pick-products]')
    if (!element) { return; }

    var tileElements = Array.from(element.querySelectorAll('input'));
    tileElements.forEach(function(tileElement){
      tileElement.removeEventListener('change', onTileElementClicked);
      tileElement.addEventListener('change', onTileElementClicked);
    })
  }

  function onTileElementClicked(event){
    var target = event.currentTarget;

    var selected = selectedTileElementsCount();
    var tileElements = selectedTileElements();

    if (selected > 3) {
      target.checked = false
    }

    handleProductTypes();
  }

  function selectedTileElements(){
    var element = document.querySelector('[data-pick-products]')
    var tileElements = Array.from(element.querySelectorAll('[data-element]'));
    return tileElements;
  }

  function selectedTileElementsCount(){
    var tileElements = selectedTileElements();
    var selected = 0;

    tileElements.forEach(function(element){
      if (element.checked){
        selected += 1;
      }
    })

    return selected;
  }

  function handleProductTypes(){
    var pickProductsElement = document.querySelector('[data-pick-products]')
    var allProductsElement = document.getElementById('all_product_types')
    if (allProductsElement && pickProductsElement) {
      var data = JSON.parse(allProductsElement.value)

      Array.from(document.querySelectorAll('[data-product-type-id]')).forEach(function(element){
        element.style.fontWeight = null;
      })

      var selected = selectedTileElements();
      selected.forEach(function (element) {
        if (element.checked){
          element.style.color = 'green'
          for (var i = 0; i < data.length; i++) {
            if (data[i][0] == element.value) {
              var toHighlightElement = document.querySelector('[data-product-type-id="' + data[i][0] + '"]');
              toHighlightElement.style.fontWeight = 'bolder'
            }
          }
        }
      })
    }
  }
})();
