$(document).ready(function () {
  $('.js-new-product-section').on('change', function () {
    var backgroundElement = $('#js-new-preview-background');
    backgroundElement.removeClass();
    backgroundElement.addClass("preview-title " + this.value + "-background");

    var titleElement = $('#js-new-preview-title-section');
    titleElement.removeClass();
    titleElement.addClass(this.value + "-title");
  });
});
