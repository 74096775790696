import 'summernote'

$(document).ready(function () {
  $('textarea.html-input').summernote({
    toolbar: [
      ['style', ['bold', 'italic', 'underline', 'clear', 'style']],
      // ['fontsize', ['fontsize']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['insert', ['link']],
      ['view', ['fullscreen', 'help']],
    ],
    styleTags: ['p', 'h1', 'h2', 'h3'],
    fontSizes: ['10', '11', '12', '13', '14', '15', '16', '17' , '18', '19', '20']
  }).on('summernote.change', function (e) {
    var me = $(this);
    me.trigger('change');
  });

  $('textarea.html-input-advanced').summernote({
    toolbar: [
      ['style', ['bold', 'italic', 'underline', 'clear', 'style']],
      // ['fontsize', ['fontsize']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['insert', ['link']],
      ['view', ['fullscreen', 'codeview', 'help']],
    ],
    styleTags: ['p', 'h1', 'h2', 'h3'],
    fontSizes: ['10', '11', '12', '13', '14', '15', '16', '17' , '18', '19', '20']
  }).on('summernote.change', function (e) {
    var me = $(this);
    me.trigger('change');
  });
});
