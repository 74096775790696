(function(){

  var $notificationElements = [];

  $(document).ready(function () {
    $notificationElements = $('[data-notification]');
    $notificationElements.on('change', function(_event){

      var $notificationElement = $(this);
      var displayElement = $($notificationElement.data('displayElementSelector'));

      if ($notificationElement.is(':checked')) {
        displayElement.addClass('notification-active');
      } else {
        displayElement.removeClass('notification-active');
      }
    });
  });
})();
