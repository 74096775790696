const Counter = (function () {
  function init() {
    Array.from(document.querySelectorAll('[data-count]')).forEach(function(element){
      initCounter(element);
      handleCounterChanged(element)
    });
  }

  function initCounter(element) {
    $(element).on('keyup change', function () {
      handleCounterChanged(element)
    });
  }

  function handleCounterChanged(element){
    const resultElement = document.querySelector(element.dataset.target);
    if (!resultElement) { return; }

    resultElement.classList.remove(element.dataset.warningClass)
    resultElement.classList.remove(element.dataset.errorClass)

    const characters = countCharacters(element);
    const warningThreshold = element.dataset.warningThreshold != undefined ? parseInt(element.dataset.warningThreshold) : null
    if (warningThreshold && characters > warningThreshold) {
      resultElement.classList.add(element.dataset.warningClass);
    }

    const errorThreshold = element.dataset.errorThreshold != undefined ? parseInt(element.dataset.errorThreshold) : null
    if (errorThreshold && characters > errorThreshold) {
      resultElement.classList.add(element.dataset.errorClass);
    }

    resultElement.innerText = characters + "";

    const wordsElement = document.querySelector(element.dataset.wordsTarget);
    if (wordsElement){
      wordsElement.innerText = countWords(element) + "";
    }

    const mostWordsElement = document.querySelector(element.dataset.mostWordsTarget);
    if (mostWordsElement){
      mostWordsElement.innerText = getCombinations(element) + "";
    }
  }

  function countCharacters(element) {
    //remove all html
    return element.value.replaceAll(/<.*?>/g, '').replaceAll(/<\/.*?>/g, '').length;
  }

  function countWords(element) {
    return getWords(element).length;
  }

  function getWords(element) {
    let sanitized = element.value.replaceAll(/<span.*?>/g, '').replaceAll(/<\/span>/g, '')
    sanitized = sanitized.replaceAll(/<.*?>/g, ' ').replaceAll(/<\/.*?>/g, ' ')

    const words = sanitized.match(/(\w+)/g);
    if (words == null) { return []; }

    const filtered = words.filter(function(value, index, arr){
      return value.length > 1 && value != 'nbsp' && value != 'br' && !value.includes('>');
    });

    return filtered
  }

  function getCombinations(element) {
    const words = getWords(element);
    if (words == null){ return ""; }

    let data = {};
    let index = 0;

    words.forEach(word => {
      if (word.length > 3 && word != 'nbsp') {
        const subWords = getWords(element);

        let previousWord = null;
        let nextWord = null;

        if (index > 0) {
          previousWord = subWords[index - 1];
        }
        if (index < subWords.length - 1) {
          nextWord = subWords[index + 1]
        }

        if (nextWord != null && nextWord.length > 3 && nextWord != 'nbsp') {
          if (data.hasOwnProperty(word + "|" + nextWord)) {
            const count = data[word + "|" + nextWord];
            data[word + "|" + nextWord] = count + 1;
          } else {
            data[word + "|" + nextWord] = 1;
          }
        }

        if (previousWord != null && previousWord.length > 3 && previousWord != 'nbsp') {
          if (data.hasOwnProperty(word + "|" + previousWord)) {
            const count = data[word + "|" + previousWord];
            data[word + "|" + previousWord] = count + 1;
          } else {
            data[word + "|" + previousWord] = 1;
          }
        }
      }

      index += 1;
    })

    let mostUsedCombo = ""
    let mostUsed = 0;

    for (let item in data){
      if (data[item] > mostUsed){
        mostUsedCombo = item;
        mostUsed = data[item]
      }
    }

    if (mostUsedCombo == '') { return mostUsedCombo;}

    return mostUsedCombo.split('|').join(', ') + "(" + mostUsed + ")";
  }

  return {
    init: init
  }
})();

window.Counter = Counter;
