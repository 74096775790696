const headersHash = {
  'pragma': 'no-cache', //No caching!
  'cache-control': 'no-cache',
  'X-Requested-With': 'XMLHttpRequest',
}

$(document).ready(function () {
  var storedFlashMessage = getStoredFlashMessage();
  if (storedFlashMessage !== undefined && storedFlashMessage != null && storedFlashMessage != "null"){
    clearStoredFlashMessage();
    renderFlash(storedFlashMessage);
  }

  var ajaxSubmits = document.querySelectorAll('[data-ajax-post]')
  ajaxSubmits.forEach(function(element){
    element.removeEventListener('click', ajaxSubmit)
    element.addEventListener('click', ajaxSubmit)
  })
});

function ajaxSubmit(event){
  event.stopPropagation();
  event.preventDefault();

  var status = ''
  var element = event.currentTarget;
  var form = element.form;
  var livePreviewElement = document.querySelector(element.dataset.remoteResponseTarget);

  if (livePreviewElement.parentElement.classList.contains('active')){
    livePreviewElement.parentElement.classList.remove('active')
    element.value = 'Preview'
    return;
  }

  var initObject = {
    method: 'PATCH',
    headers: new Headers(headersHash),
    credentials: 'include'
  };

  const url = form.getAttribute('action')
  const request = new Request(url);

  var formData = new FormData();
  form.querySelectorAll('input').forEach(function(element){

    if (element.type == 'checkbox') {
      if (element.checked){
        formData.append(element.name, element.value)
      }
    }
    else if (element.type == 'file' && element.value) {
      formData.append(element.name, element.files[0])
    } else {
      formData.append(element.name, element.value)
    }
  })

  formData.append('commit', 'Preview')

  formData.append('authenticity_token', document.head.querySelector('meta[name="csrf-token"]').getAttribute('content'))
  initObject.body = formData;

  fetch(request, initObject)
    .then(function (response) {
      status = response.status
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json();
      } else {
        return response.text();
      }
    })
    .then(function (data) {
      if (status < 300) {
        livePreviewElement.parentElement.classList.add('active')
        // livePreviewElement.innerHTML = data
        var doc = livePreviewElement.contentWindow.document;
        doc.open();
        doc.write(data);
        doc.close();
        element.value = 'Sluiten'
      } else {

      }
    })
    .catch(function (error) {
    });
  return false
}

$(document).on('ajax:success', '[data-remote-response-target]', function (event) {
  const target = $($(this).data('remote-response-target'));
  target.html(event.detail[0].body.innerHTML);
});

$(document).on('ajax:success', function (event) {
  var data = '';
  var status = '';
  var xhr = '';

  [data, status, xhr] = event.detail;

  if (data.status == undefined) {
    data = JSON.parse(xhr.response);
  }

  if (data.status === "ok") {
    if (data.url !== undefined) {
      if (data.flash !== undefined) {
        storeAndRenderFlash(data.flash);
      }
      window.location = data.url;
      return
    }

    if (data.flash !== undefined) {
      renderFlash(data.flash);
      $('#loginModal').modal('toggle')
    }

  } else {
    if (Array.isArray(data.errors)){
      var errorElement = document.getElementById(data.target);
      errorElement.style.display = 'block';
      var html = "";
      for (var i = 0; i < data.errors.length; i++) {
        html += data.errors[i] + "<br />";
      }
      errorElement.innerHTML = html;
    } else {
      var errorElement = document.getElementById(data.target);
      errorElement.style.display = 'block';
      errorElement.innerHTML = data.errors;
    }
  }
});

function renderFlash(message){
  var alert = document.createElement('div');
  alert.classList.add('flash-alert');
  alert.innerText = message;
  document.body.insertBefore(alert, document.body.firstChild);
}

function storeAndRenderFlash(message) {
  if (window.localStorage !== undefined) {
    window.localStorage.setItem('flash', message)
  }
}

function getStoredFlashMessage() {
  if (window.localStorage !== undefined) {
    return window.localStorage.getItem('flash')
  }
}

function clearStoredFlashMessage() {
  if (window.localStorage !== undefined) {
    window.localStorage.setItem('flash', null)
  }
}
