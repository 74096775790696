/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import 'babel-polyfill'
import 'whatwg-fetch'
import '../script/init'


import Rails from 'rails-ujs'
// import Turbolinks from 'turbolinks'
import 'bootstrap';
// import './images' voorlopig nog niet. Dit werkt niet fijn. Assets pipeline is nu nog beter
import '../script/elements/counter'
import '../script/elements/observe_fields'
import '../script/elements/new_product'
import '../script/elements/tile-elements'
import '../script/controls/file_upload'
import '../script/controls/video_embed'
import '../script/controls/banner'
import '../script/controls/user_edit_form'
import '../script/orders/new-order-form'
import '../script/summernote/summernote'
import '../script/select2/select2init'
import '../script/elements/ajax-form'
import '../script/elements/ajax-download-button'
import '../script/elements/login-form'
import '../script/elements/part'
import '../script/elements/notification'
import '../script/elements/sticky-element'
import '../script/elements/confirmation'
import '../script/elements/wizard'

document.addEventListener("DOMContentLoaded", function (_event) {
  if (window._rails_loaded !== true) {
    Rails.start();
    window.Rails = Rails
  }
});
