(function(){
  $(document).ready(function () {
    var editEmail = $('#email_input');
    var editPassword = $('#new_password_input');
    editEmail.data('originalValue', editEmail.val());
    editPassword.data('originalValue', editPassword.val());

    editEmail.on('keyup', function(event){
      var passwordElement = $('.js-password');
      if ($(this).val() != $(this).data('originalValue')) {
        passwordElement.addClass(passwordElement.data('active-class'));
      } else {
        passwordElement.removeClass(passwordElement.data('active-class'));
      }
    });

    editPassword.on('keyup', function(event){
      var passwordElement = $('.js-new-password');
      if ($(this).val() != $(this).data('originalValue')) {
        passwordElement.addClass(passwordElement.data('active-class'));
      } else {
        passwordElement.removeClass(passwordElement.data('active-class'));
      }
    });
  });
})();
