// met Turbolinks: turbolinks:load
$(window).on('load generate-video-embed', function () {
  $('*[data-embed-video-url]').each(function () {
    var value = $(this).data('embed-video-url');

    // match info: https://github.com/thibaudgg/video_info/tree/master/lib/video_info/providers
    if (/(youtube\.com\/(?!playlist|embed\/videoseries).*)|(youtu\.be)/.test(value)) {
      var video_id = value.match(/(?:youtube(?:-nocookie)?\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/);

      if (video_id) {
        $(this).addClass('embed-responsive').addClass('embed-responsive-16by9');
        $('<iframe/>').attr('src', "//www.youtube.com/embed/" + video_id[1]).appendTo($(this)).addClass('embed-responsive-item');
      }
    }

    if (/(vimeo\.com\/(?!album|hubnut\/album).*)/.test(value)) {
      var video_id = value.match(/.*\.com&&|\/(?:(?:groups\/[^\/]+\/videos\/)|(?:ondemand|channels)(?:(?:\/less\/)|(?:user[0-9]+\/review\/)?([0-9]+).*|(?:\/\w*\/))|(?:video\/))?([0-9]+).*$/);

      if (video_id) {
        $(this).addClass('embed-responsive').addClass('embed-responsive-16by9');
        $('<iframe/>').attr('src', "//player.vimeo.com/video/" + video_id[2]).appendTo($(this)).addClass('embed-responsive-item');
      }
    }

    $(this).removeAttr('data-embed-video-url');
  });
});
