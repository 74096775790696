(function() {
  function handleDownloadLinkClicked(event) {
    event.preventDefault()

    const $target = $(event.currentTarget)
    const $container = $target.parent('.ajax-download-button')

    const $doneIcon = $container.find('.ajax-download-button__icon-done')
    const $waitingIcon = $container.find('.ajax-download-button__icon-waiting').removeClass('d-none')

    $doneIcon.addClass('d-none')
    $waitingIcon.removeClass('d-none')

    const url = $target.attr('href')

    const request = buildRequest(() => {
      $waitingIcon.addClass('d-none')
      $doneIcon.removeClass('d-none')
    })

    sendRequest(url, request)
  }

  function sendRequest(url, request) {
    request.open("GET", url, true)
    request.responseType = "blob"
    request.send();
  }

  function buildRequest(handleLoadEnd) {
    const request = new XMLHttpRequest();

    request.onerror = () => {
      // TODO: alert
      console.log('error')
    }

    request.onload = () => {
      const blob = request.response
      const fileName = request
        .getResponseHeader('content-disposition')
        .split(';')[1]
        .match(/"(.+)"/)[1]
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName
      link.click()
    }

    request.onloadend =  handleLoadEnd

    return request
  }

  function initDownloadLinks() {
    $('.ajax-download-button__link').on('click', handleDownloadLinkClicked)
  }

  $(document).ready(initDownloadLinks)
})()
