$(document).ready(function () {
  if ($("#order_presenter_create_account")) {
    observe_order_account_creation();
    order_account_creation();
  }
});

function observe_order_account_creation() {
  $("#order_presenter_create_account").on('change', order_account_creation);
}

function order_account_creation() {
  if ($('#order_presenter_create_account').is(':checked')) {
    $('.js-create-account').show();
  } else {
    $('.js-create-account').hide();
  }
}
